import React from 'react'

type MyProps = {
  productId?: string
  productTitle?: string
  renderBlock: 'allReviews' | 'productReview'
}

const JudgeMe = ({ productId, productTitle, renderBlock }: MyProps) => {
  return renderBlock === 'allReviews' ? (
    <div className="jdgm-widget jdgm-all-reviews-widget">
      <div className="jdgm-all-reviews__body"></div>
    </div>
  ) : (
    <div
      id="judgeme_product_reviews"
      className="jdgm-widget jdgm-review-widget jdgm-outside-widget"
      data-id={productId}
      data-product-title={productTitle}
    ></div>
  )
}

export default JudgeMe
